import { required, email } from 'vuelidate/lib/validators'

export default {
    data: () => ({
        form: {
            email: "",
            password: "",
        },
        has_error : false,
        message : "",
    }),
    validations: {
        form: {
            email: { required, email },
            password: { required },
        }
    },
    created() {
        this.$store.commit('SET_LAYOUT', 'login-layout');
    },
    methods: {
        async login() {
            let loader = this.$loading.show({color: '#0055ff',container: null});
            try{
                await this.$store.dispatch("login", this.form);
                window.location.href = "/";

            }catch(error){
                switch(error.response.status){
                    case 422:
                        this.has_error = true;
                        this.message = "Usuario o contraseña incorrectos";
                    break;
                    default :
                        this.has_error = true;
                        this.message = "Ha ocurrido un error, por favor intente nuevamente";
                    break;
                }
            }

            loader.hide();
        }
    }

};
